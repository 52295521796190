module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Toastmasters D95A Division Conference 2024","short_name":"D95A Conference 2024","start_url":"/","background_color":"#004165","theme_color":"#004165","display":"minimal-ui","icon":"src/static/images/logos/TMI-Logo-World-Only.png","icons":[{"src":"/favicons/android-chrome-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-chrome-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d9e8fb1cdc8587507f728d97975a92c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
